import _ from 'underscore'
import Backbone from 'backbone'

import {
    passwordStrength,
} from 'util/twistle'

import { ajax } from 'util/ajax'
import { renderTemplate } from 'util/template_renderer'

const PasswordResetView = Backbone.View.extend({
    className:"password_reset_view",
    events: {
        'click .password_reset_submit_button': 'passwordResetSubmit',
        'submit .password_reset_submit_button': 'passwordResetSubmit',
        'keyup input[name=new_password]': 'passwordStrength',
        'click .login_return': 'noReset'
    },
    options:{

    },
    initialize: function() {
        var self = this;
        self.render();
    },

    render: function() {
        var self = this;
        _.bindAll.apply(_, [this].concat(_.functions(this)));
        self.$el.html(renderTemplate('login_password_reset_template', self.options));
        return self;
    },
    passwordStrength: function(evt){
        var self = this;
        var currentPwd = self.$("input[name=new_password]").val();
        if(currentPwd.length > 0){
            var strength = passwordStrength(currentPwd);
            self.$(".password_message").alterClass("pw_strength_*", "pw_strength_"+strength.value);
            self.$(".password_message").html(strength.message);
        }
        else{
            self.$(".password_message").html("").alterClass("pw_strength_*");
        }

    },
    passwordResetSubmit: function(evt) {
        var self = this;
        evt.preventDefault();
        var currentPwd = self.$("input[name=new_password]").val();
        var strength = passwordStrength(currentPwd);

        if(strength.value < 1){
            self.$("input[name=new_password]").addClass("form_error");
            self.$("input[name=new_password_confirm]").addClass("form_error");
            self.$(".password_reset_message_area").stop().css("opacity", 1).show().text("Passwords should be at least 6 characters long").fadeOut(20000);
            return;
        }

        //Check for non-matching password
        if(self.$("input[name=new_password]").val() !== self.$("input[name=new_password_confirm]").val()) {
            self.$("input[name=new_password]").addClass("form_error");
            self.$("input[name=new_password_confirm]").addClass("form_error");

            self.$(".password_reset_message_area").stop().css("opacity", 1).show().text("Passwords do not match").fadeOut(20000);

            return;
        }

        ajax.request('/account/ConfirmReset', {
            password: self.$("input[name=new_password]").val(),
            e: App.config.get("reset_e"),
            t: App.config.get("reset_t")
        }, [], self.resetSuccess, true, self.resetFailure, self.$(".password_reset_submit_button"));
    },

    resetSuccess: function(response) {
        var self = this;

        App.config.set("reset_e", "");
        App.config.set("reset_t", "");
        self.$(".form_field_row").hide();
        self.$(".password_reset_message_area").stop().css({"opacity":1,"color":"#aaa"}).show().text("Password successfully changed! Logging you in...");
        window.setTimeout(function(){
            App.trigger("app:handle_auth_success");
        },250);

    },

    resetFailure: function(response) {
        var self = this;
        self.$(".password_reset_message_area").stop().css("opacity", 1).show().text("Failure resetting password -- please follow the password reset steps again").fadeOut(30000);
    },

    noReset: function(evt) {
        App.config.set("reset_e", "");
        App.config.set("reset_t", "");
        App.trigger("app:reset_login_desktop");
    }
});

export default PasswordResetView;
