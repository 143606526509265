import _ from 'underscore'
import { ajax } from 'util/ajax'
import { formatDate } from 'util/twistle'
import { renderTemplate } from 'util/template_renderer'
import { timeAgoInWords } from 'util/date'
import DialogView from 'app/views/dialog_view'

const NoteParticipantStatusDialogView = DialogView.extend({
    className: "later_dialog",
    initialize: function(options) {

        this.options = _.extend({}, this.options, options, {
            width: 460,
            title:'Message Status'
        });

        DialogView.prototype.initialize.call(this, this.options);

        return this.render();

    },

    events:function(){
        return _.extend({}, DialogView.prototype.events() || {}, {

        });
    },



    render: function(afterUpdate) {

        var self = this;
        _.bindAll.apply(_, [this].concat(_.functions(this)));

        self.getEl().spin();

        // refetch the model
        ajax.request('/v2/conversation/ViewNoteStatus', {note_id: self.options.noteId}, [], function (resp) {
            var accounts = self.model.get("accounts");
            var note = self.model.getNoteById(self.options.noteId);
            var participantStatusList = [];
            var noteAuthor, originalAuthor;

            _.each(resp.read_status || {}, function (status, username) {
                var account =  accounts[username];
                if(username !== App.account.get("username") && account && account.accountype !== 's') // groups can't read something
                {
                    participantStatusList.push({
                        name:account.formalname,
                        status:status ? timeAgoInWords(status, true) : "",
                        fullDate:status ? formatDate(status) : ""
                    });
                }
            });

            noteAuthor = accounts[note.author_id].formalname;

            if (resp.original_author && resp.original_author.username !== note.author_id) {
                originalAuthor = resp.original_author.formalname;
            }

            self.getEl().html(renderTemplate('note_participant_status_template', {
                noteAuthor: noteAuthor,
                originalAuthor: originalAuthor,
                noteDate: formatDate(note.date),
                notification_message: note.notification_message,
                participantStatusList: participantStatusList
            }));

        }, false, function(err){
            self.getEl().spin({"stop":true});
        });

        return self;

    }

});

export default NoteParticipantStatusDialogView;