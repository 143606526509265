import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'

import {
    areCookiesEnabled,
    getQuerystringParam,
} from 'util/twistle'

import { renderTemplate } from 'util/template_renderer'
import PasswordResetView from 'app/views/login_password_reset_view'
import ForgotPasswordView from 'app/views/login_forgot_password_view'
import InviteCodeSignupView from 'app/views/invite_code_signup_view'

// Top-level view mainly responsible for rendering all the subviews of the desktop
App.LoginDesktopView = Backbone.View.extend({
    currentView: null,
    events: {
        'click .login_header':'loginHeaderTap'
    },
    openviews: [],
    initialize: function() {
        var self = this;
        _.bindAll.apply(_, [self].concat(_.functions(self)));
        App.bind("app:navigate_to_app_desktop", self.showDesktop, self);
        App.bind("app:start_invite_code_signup", self.inviteCodeSignup, self);
        App.bind("app:reset_login_desktop", self.reset, self);
        App.bind("app:start_account_setup", self.startAccountSetup, self);
        App.bind("app:continue_account_setup", self.continueAccountSetup, self);
        App.bind("app:forgot_password", self.startForgotPassword, self);
        App.bind("app:password_reset", self.startPasswordReset, self);
        App.bind("app:handle_auth_success", self.handleSuccessfulAuth, self);
    },

    setupContainer: function(){
        var self = this;
        if(!self.containerSetupComplete)
        {
            self.setElement($("#login_main"));
            self.containerSetupComplete = true;
        }
    },

    render: function() {
        var self = this;

        self.setupContainer();

        if(!areCookiesEnabled())
        {
            self.$(".login_bottom").empty().html(
                renderTemplate('no_cookies_template', App.config.attributes)
            );
        }
        else
        {
            self.setMode("login_mode");
            self.openView(new App.LoginFormView({firstLaunch:self.options.firstLaunch}));
            self.$(".login_top_right").show(); // show the top right banner quadrant
        }

        self.rendered = true;

        return self;
    },

    reset: function(){
        var self = this;
        self.setMode("login_mode");
        App.router.navigate("");
        if(!self.rendered)
        {
            self.render();
        }
        else
        {
            self.closeView();
            self.openView(new App.LoginFormView());
        }
    },

    handleSuccessfulAuth: function(){
        let self = this;
        // after authentication, route to the correct destination (either into the app, or back into a self-enrollment
        // flow)
        if(!App.config.get("inviteCodeContact"))
        {
            App.trigger("app:user_did_login");
            App.trigger("app:navigate_to_app_desktop");
        }
        else
        {
            App.account.fetch({success:function()
            {
                App.trigger("app:start_account_setup", {
                    fromLogin: true,
                    isFromInviteCode: true
                });
            }});
        }
    },

    showDesktop: function() {
        // if a destination has been specified in the url that loaded the login page,
        // redirect to that hash fragment (deep linking). Otherwise they just get logged into the app.\

        // wipe any stored account
        if(App.config.getLocalSetting("twistle_db_user"))
        {
           App.config.removeLocalSetting("twistle_db_user");
        }

        var destination = getQuerystringParam("dest", false);

        var url = "/desktop/";
        if (destination && destination.length > 0) {
            if (destination.substr(0, 1) === "/") {
                url = destination; // they are headed somewhere else
            }
            else {
                url += "#" + destination; // normal object within our "desktop" SPA
            }
        }
        window.location.href = url;

    },

    inviteCodeSignup: function() {
        var self = this;
        self.setMode("registration_mode");
        self.openView(new InviteCodeSignupView());
    },

    openView: function(view) {
        var self = this;
        self.setupContainer();
        self.closeView();
        self.currentView = view;
        self.$(".login_bottom").empty().append(view.$el);
    },

    closeView: function() {
        var self = this;
        if(self.currentView) {
            self.currentView.close();
        }
    },

    setMode: function(mode) {
        if ( mode === "login_mode") {
            $("body").removeClass("registration_mode").addClass("login_mode");
        }
        else {
            $("body").addClass("registration_mode").removeClass("login_mode");
        }
    },

    onClose: function(){
        $("body").removeClass("registration_mode").removeClass("login_mode");
        $(".growl").remove();
    },

    startAccountSetup: function(params) {
        var self = this;
        self.setMode("registration_mode");
        self.$el.parent().addClass("wide");

        //Only go to setup if we're logged into a provisional account
        if(!App.config.get("isProvisional") && !params.isFromInviteCode) {
            self.openView(new ForgotPasswordView(params));
        }
        else {
            if (! (params && params.isFromInviteCode)){
                App.router.navigate("setup");
            }
            self.closeView();
            self.openView(new App.AccountSetupView(params));
        }
    },

    continueAccountSetup: function() {
        var self = this;
        //assumes currentview is the account setp
        if(self.currentView) {
            self.currentView.render();
        }
        else {
            self.startAccountSetup();
        }
    },

    startForgotPassword: function(params) {
        var self = this;
        App.router.navigate("forgot_password");

        self.setMode((params && params.registration_mode) ? "registration_mode" : "login_mode");

        self.openView(new ForgotPasswordView(params));
    },

    startPasswordReset: function() {
        var self = this;
        //Only go to password reset if we've got the proper reset values
        if(!App.config.get("reset_e") || !App.config.get("reset_t")) {
            App.trigger("app:reset_login_desktop");
        }
        else {
            App.router.navigate("reset");
            self.openView(new PasswordResetView({}));
        }
    },

    loginHeaderTap:function(evt)
    {
        var $targ = $(evt.currentTarget);
        App.trigger("app:login_header_tapped", $targ);
    }
});
