import _ from 'underscore'
import $ from 'jquery'
import Backbone from 'backbone'
import {
    cleanPhone,
} from 'util/twistle'

import {
    validateEmailAddress,
    validateField,
} from 'util/validators'

import { renderTemplate } from 'util/template_renderer'

import { Specialties } from 'data/specialties'

// Renders the conversation detail view, used for viewing a conversation, or starting a new one
const InviteMultipleHelperView = Backbone.View.extend({
    className: "people_input_wrapper",
    events: {
        "click .add_row_button": "addRowClicked",
        "click .show_upload_button": "showUploadClicked",
        "click .show_all_button": "showAllRows"
    },
    options: {
        relationship: "org-member",
        startRows:4,
        allowUpload:false,
        alwaysShowMore: false,
        uploadSpreadsheetTemplate:"Account-Spreadsheet-Template.xlsx"
    },
    warningFadeTimeout:35000,

    initialize: function(options) {
        const self = this;
        self.options = _.extend({}, self.options, options);
        this.render();
        this.numErrors = 0;
    },

    render: function() {
        var self = this;
        var i;
        _.bindAll.apply(_, [this].concat(_.functions(this)));

        if (self.options.allowUpload){
            switch (self.options.relationship){
                case "patient":
                    self.options.uploadSpreadsheetTemplate = "Patient-Spreadsheet-Template.xlsx";
                    break;
                case "org-member":
                    self.options.uploadSpreadsheetTemplate = "Staff-Spreadsheet-Template.xlsx";
                    break;

            }
        }

        self.$el.html(renderTemplate('invite_multi_helper_template', self.options));
        self.$(".more_info." + self.options.relationship + "_more_info").show();
        self.$inviteAccountsWrap = self.$(".multi_invite_accounts");
        self.rowCount = 0;

        for(i=0;i<self.options.startRows;i++){
            self.addRow();
        }
        self.finishAddingRows();
        setTimeout(function(){
            self.postRender();
        }, 0);

        return this;
    },

    postRender: function() {
        var self = this;

        if (self.options.relationship === "org-member"){
            $(".specialty").autocomplete({
                source: Specialties,
                delay: 0,
                position: {
                    collision: "flip"
                }
            });
        }
        if (self.options.allowUpload){
            self.$(".template_upload_button").twistle_attachments({useWrap:true,
                                                                   isMultiple:false,
                                                                   fileListUi:self.$(".spreadsheet_upload_file"),
                                                                   uploadUrl: "/util/ParseAccountSpreadsheet",
                                                                   uploadFileName: "spreadsheet_file",
                                                                   upload_success: self.uploadSuccess,
                                                                   additionalUploadParams: [["relationship", self.options.relationship]]});
        }

    },

    addRowClicked: function(){
        var self = this;
        self.addRow();
        self.finishAddingRows();
        App.trigger("app:multi_invite_row_added");
    },

    addRow: function(data){
        var self = this;
        let paramData = _.extend(data || {}, {row:self.rowCount}, self.options);
        var row = $(renderTemplate('invite_row_template', paramData));
        if (data && self.rowCount > 4){
            row.hide();
        }
        row.appendTo(self.$inviteAccountsWrap);
        self.rowCount++;
    },

    renderErrors: function(failures){
        var self = this;
        self.numErrors = 0;

        // make sure errors don't get lost in the "show more rows" dropdown
        self.showAllRows();

        // failures maps a one-indexed row to an error message
        Object.keys(failures).forEach(rowIndex => {
            self.numErrors += 1;

            let entryRow = self.$inviteAccountsWrap[0].children[rowIndex-1];

            if (entryRow) {
                $(entryRow).find(".error_display").text(failures[rowIndex]);
                $(entryRow).addClass("invite_row_error");
            }
        });
    },

    resetError: function(element){
        var self = this;

        if (element && $(element).hasClass("invite_row_error")) {
            $(element).find(".error_display").empty();
            $(element).removeClass("invite_row_error");

            self.numErrors -= 1;
        }
    },

    finishAddingRows: function(){
        var self = this;
        self.$("input.firstname,input.lastname").autoCapField();
        if (self.options.alwaysShowMore){
            self.$("." + self.options.relationship + "_more_info").show();
        }
        if (self.rowCount > 4) {
            self.$(".show_all_button").html("Show all rows ("+ (self.rowCount - 5) +" more)...").show();
        }
    },

    showAllRows: function(){
        var self = this;
        self.$(".invite_row").show();
        self.$(".show_all_button").hide();
    },

    showUploadClicked: function(e){
        var self = this;
        self.$(".show_upload_button").hide();
        self.$(".multi_invite_accounts_wrap").hide();
        self.$(".spreadsheet_upload_wrap").fadeIn("fast");
    },

    uploadSuccess: function(evt, resp){
        var self = this;
        self.$(".spreadsheet_upload_wrap").fadeOut("fast");
        if(resp.accounts && resp.accounts.length){
            // render rows...
            self.rowCount = 0;
            self.$inviteAccountsWrap.html("");
            _.each(resp.accounts, self.addRow);
            self.finishAddingRows();
            self.$(".add_row_button").hide();
            self.$(".multi_invite_accounts_wrap").fadeIn("fast");
            App.trigger("app:multi_invite_row_added");
        }
    },

    getMultipleInvites: function(requiresDOB, requiresMRN) {
        var self = this;
        var params = {};
        var hasErrors = false;
        var accountCount = 0;
        self.$(".invite_row").each( function(idx, rowEl ) {
            var rowHasInput = false, rowHasErrors = false, $rowEl = $(rowEl);

            _.each([["firstname",""],["lastname",""],["email",""]], function(field){
                var input = $rowEl.find("." + field[0]);
                if(validateField(input.val(),field[1])){
                    rowHasInput = true;
                    return false;
                }
            });

            var inputFields = [["firstname", ""],
                ["lastname", ""],
                ["email", "emailOrPhone"]];

            if (self.options.relationship === "patient") {
                inputFields.push(["dob", "date"]);
                inputFields.push(["client_identifier", ""]);
            }

            if(rowHasInput){
                _.each(inputFields, function (field)
                {
                    var fieldName = field[0],
                        input = $rowEl.find("." + fieldName);

                    if (!input) {
                        return;
                    }

                    var inputVal = input && input.val();

                    // Allow DOB and Client ID to be blank
                    var validateInput = true;
                    if (fieldName === "dob" && !requiresDOB) {
                        if (!inputVal) {
                            validateInput = false;
                        }
                    }

                    if (fieldName === "client_identifier" && !requiresMRN) {
                        if (!inputVal) {
                            validateInput = false;
                        }
                    }

                    if (validateInput && !validateField(inputVal, field[1]))
                    {
                        input.addClass("form_error");
                        hasErrors = true;
                        rowHasErrors = true;
                    }
                    else
                    {
                        input.removeClass("form_error");
                        if (fieldName === "email")
                        {
                            // figure out if this is email or phone
                            if(validateEmailAddress(inputVal))
                            {
                                params["accounts_" + accountCount + "_email"] = inputVal;
                            }
                            else
                            {
                                params["accounts_" + accountCount + "_phone"] = cleanPhone(inputVal);
                            }
                        }
                        else
                        {
                            params["accounts_" + accountCount + "_" + fieldName] = inputVal;
                        }
                    }
                });
                if (rowHasErrors){
                    $rowEl.show();
                }
                accountCount++;
            }
        });
        return {errors:hasErrors, accounts:params};

    }

});

export default InviteMultipleHelperView;